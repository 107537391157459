<template>
  <SectionWrap id="cta-enquire" small centred bg-color="white">
    <h2>{{ $t('component.cta-enquire.title') }}</h2>
    <p>{{ $t('component.cta-enquire.description') }}</p>
    <div>
      <ButtonIcon
        class="btn--secondary"
        href="https://forms.your.unimelb.edu.au/4746909"
      >
        {{ $t('component.cta-enquire.btn') }}
      </ButtonIcon>
    </div>
  </SectionWrap>
</template>

<script>
import { SectionWrap, ButtonIcon } from '@unimelb/pattern-lib-vue';

export default {
  components: {
    SectionWrap,
    ButtonIcon,
  },
};
</script>
