<template>
  <main v-if="studyArea && !studyArea.error" id="main" class="interest">
    <PageHeaderAlt
      :title="studyArea.title"
      :tag="{
        text: studyAreaCategory,
        href: '/find/?f.Tabs%7CtypeStudyArea=Study+areas',
        color: 'purple',
      }"
      :introduction="studyArea.banner.introduction"
      :img="{
        src: studyArea.thumbnail_url,
        alt: studyArea.thumbnail_alt_txt || '',
      }"
    />
    <SectionAlt bg-color="alt">
      <template #left>
        <h2 data-test="study-area-page-description" class="heading-md">
          {{ $t('page.interest.description') + studyArea.title }}
        </h2>
      </template>
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="studyArea.overview.content" />
      <!-- eslint-enable vue/no-v-html -->
    </SectionAlt>
    <SectionAlt v-if="videoDetails">
      <template #left>
        <h2 class="heading-md">
          {{ videoDetails.heading }}
        </h2>
        <!-- eslint-disable vue/no-v-html -->
        <div class="shim-mb1" v-html="videoDetails.description" />
        <!-- eslint-enable vue/no-v-html -->
        <ButtonIcon
          v-if="videoDetails.cta"
          :href="videoDetails.cta.link"
          class="btn--text"
        >
          {{ videoDetails.cta.label }}
        </ButtonIcon>
      </template>
      <VideoPlayer
        :label="videoDetails['video-api'].label"
        :poster="videoPoster"
        :video="videoObject"
      />
    </SectionAlt>
    <SectionAlt v-if="relatedStudyAreas && relatedStudyAreas.length">
      <template #left>
        <h2 class="heading-md">
          {{ $t('page.interest.related-interests.title') }}
        </h2>
      </template>
      <section aria-label="sub-interests">
        <ul aria-label="related interests" class="list-reset">
          <li
            v-for="(realtedStudyArea, index) in relatedStudyAreas"
            :key="index"
            class="interest-container__related-list-item"
          >
            <CardCourse
              :title="realtedStudyArea.title"
              :type="realtedStudyArea.type"
              :link="realtedStudyArea.link"
            />
          </li>
        </ul>
      </section>
    </SectionAlt>

    <section data-test="study-options" aria-label="study options">
      <div class="related-courses__heading bg-inverted">
        <h2 class="max">
          {{
            $t('page.interest.studyOptions.title', {
              interestName: studyArea.title,
            })
          }}
        </h2>
      </div>
      <AppTabs @tab:new="setTabActive">
        <AppTab
          v-if="relatedCourses && relatedCourses.length"
          :id="`degrees-${_uid}`"
          :count="relatedCourses.length"
          :title="$t('page.interest.studyOptions.degrees.title')"
        >
          <p>{{ $t('page.interest.studyOptions.degrees.intro') }}</p>
          <FilterSection :courses-data="relatedCourses" />
        </AppTab>
        <AppTab
          v-if="relatedComponents && relatedComponents.length"
          :id="`majors-${_uid}`"
          :count="relatedComponents.length"
          :title="$t('page.interest.studyOptions.majors.title')"
        >
          <p>{{ $t('page.interest.studyOptions.majors.intro') }}</p>
          <FilterSection :courses-data="relatedComponents" />
        </AppTab>
        <AppTab
          v-if="
            combinedShortCoursesAndMicrocredentials &&
            combinedShortCoursesAndMicrocredentials.length
          "
          :id="`short-courses-${_uid}`"
          :count="combinedShortCoursesAndMicrocredentials.length"
          :title="$t('page.interest.studyOptions.short.title')"
        >
          <p>{{ $t('page.interest.studyOptions.short.intro') }}</p>
          <FilterSection
            :courses-data="combinedShortCoursesAndMicrocredentials"
          />
        </AppTab>
      </AppTabs>
    </section>
    <SectionAlt v-if="relatedPathways && relatedPathways.length">
      <template #left>
        <h2 class="heading-md">
          {{ $t('page.interest.related-pathways.title') }}
        </h2>
      </template>
      <section aria-label="available pathways">
        <ul
          aria-label="related pathways"
          class="list-reset"
          data-test="related-pathways"
        >
          <li
            v-for="(pathway, index) in relatedPathways"
            :key="index"
            class="interest-container__related-list-item"
          >
            <CardCourse
              :title="pathway.title"
              :type="pathway.type"
              :link="pathway.link"
            />
          </li>
        </ul>
      </section>
    </SectionAlt>

    <div
      v-if="firstMidBannerImg"
      :aria-label="studyArea.mid_page_banners.banner_1.image_alt_txt"
      role="img"
      class="full-width-image"
      :style="{ backgroundImage: `url(${firstMidBannerImg})` }"
    />

    <PersonalisedAdvice />

    <WhyStudy
      v-if="studyArea.why_study && studyArea.why_study.sections.length"
      :why-study="studyArea.why_study"
      :class="{
        'shim-pb0': !studentTestimonial && !staffTestimonial && !staffProfiles,
      }"
    />

    <SectionAlt
      v-if="studentTestimonial"
      :class="{ 'shim-pb0': !staffTestimonial && !staffProfiles }"
    >
      <template #left>
        <h2 class="heading-md">
          {{ studentTestimonial.title }}
        </h2>
        <p>{{ studentTestimonial.description }}</p>
      </template>
      <TestimonialMixed
        v-if="testimonialHasContent(studentTestimonial)"
        :testimonial="studentTestimonial"
      />
    </SectionAlt>

    <SectionAlt v-if="staffTestimonial" bg-color="alt">
      <template #left>
        <h2 class="heading-md">
          {{ staffTestimonial.title }}
        </h2>
        <p>{{ staffTestimonial.description }}</p>
      </template>

      <TestimonialMixed
        v-if="testimonialHasContent(staffTestimonial)"
        :testimonial="staffTestimonial"
      />
    </SectionAlt>

    <SectionAlt v-if="staffProfiles" id="staff-profiles" class="shim-pb0">
      <template #left>
        <h2 class="heading-md">
          {{ staffProfiles.title }}
        </h2>
        <p>{{ staffProfiles.description }}</p>
      </template>
      <div class="section__inner--sml">
        <div class="grid grid--1col">
          <ListItem
            v-for="(profile, index) in staffProfiles.profiles"
            :key="index"
          >
            <CardBioHeader
              :name="profile.name"
              :title="profile.role"
              :thumb="profile.img_url"
            >
              <p>
                {{ profile.description }}
              </p>
              <ButtonIcon
                v-if="profile.cta"
                :href="profile.cta.link"
                class="btn--text"
              >
                {{ profile.cta.text }}
              </ButtonIcon>
            </CardBioHeader>
          </ListItem>
        </div>
      </div>
    </SectionAlt>

    <CtaEnquire />

    <RelatedLinks v-if="relatedLinks.length" :items="relatedLinks" />
  </main>

  <main v-else id="main" class="interest">
    <SectionWrap bg-color="white">
      <ErrorMessage>
        <h1 class="heading-card">
          {{ $t('app.errors.404.title') }}
        </h1>
        <p>{{ $t('app.errors.404.message') }}</p>
        <a :href="`${basePath}/`" class="btn btn--sml">
          {{ $t('app.errors.404.return') }}
        </a>
      </ErrorMessage>
    </SectionWrap>
  </main>
</template>

<script>
import {
  SectionWrap,
  AppTab,
  AppTabs,
  PageHeaderAlt,
  SectionAlt,
  ButtonIcon,
  CardCourse,
  VideoPlayer,
  CardBioHeader,
  ListItem,
} from '@unimelb/pattern-lib-vue';
import { get } from '@/helpers/get';
import ErrorMessage from '~/components/ErrorMessage.vue';
import FilterSection from '~/components/Filter/FilterSection.vue';
import { sluggify, trackAnalytics } from '~/helpers/client';
import imgPlaceholder from '~/assets/placeholder-new.jpg';
import PersonalisedAdvice from '~/components/PersonalisedAdvice.vue';
import CtaEnquire from '~/components/CtaEnquire.vue';
import RelatedLinks from '~/components/RelatedLinks.vue';
import TestimonialMixed from '~/components/TestimonialMixed.vue';
import WhyStudy from '~/components/WhyStudy.vue';

export default {
  components: {
    SectionWrap,
    ErrorMessage,
    AppTab,
    AppTabs,
    FilterSection,
    PageHeaderAlt,
    SectionAlt,
    ButtonIcon,
    CardCourse,
    VideoPlayer,
    PersonalisedAdvice,
    CtaEnquire,
    CardBioHeader,
    ListItem,
    RelatedLinks,
    TestimonialMixed,
    WhyStudy,
  },
  data() {
    return {
      basePath: process.env.basePath,
    };
  },
  async fetch({ store, route }) {
    await store.dispatch('study-areas/setStudyAreaData', route.params.id);

    const rootUrl = process.env.ROOT_URL;

    const breadcrumbs = [
      { text: 'Study', href: rootUrl },
      { text: 'Find a course', href: `${rootUrl}${process.env.basePath}` },
    ];

    if (store.getters['study-areas/studyAreaSlug'](route.params.id)) {
      const { title } = store.getters['study-areas/studyAreaSlug'](
        route.params.id,
      );
      breadcrumbs.push({ text: title, href: `${route.fullPath}` });
    }

    store.dispatch('setBreadcrumbs', breadcrumbs);
  },
  head() {
    return {
      title: this.metaTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.studyArea.metadata.seo_description,
        },
        { name: 'page_type', content: this.studyArea.metadata.page_type },
        { name: 'search_class', content: this.studyArea.metadata.search_class },
        {
          name: 'top_study_area_search_display',
          content: this.studyArea.top_interest,
        },
        { name: 'study_area_search_filter', content: this.topInterestsMeta },
        {
          name: 'part_of_search_display',
          content: this.topInterestsDisplayMeta,
        },
        { name: 'title_search_display', content: this.studyArea.title },
        { property: 'og:title', content: this.metaTitle },
        {
          property: 'og:description',
          content: this.studyArea.metadata.seo_description,
        },
        {
          property: 'og:image',
          content: this.studyArea.thumbnail_url
            ? this.studyArea.thumbnail_url
            : imgPlaceholder,
        },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.metaTitle },
        {
          name: 'twitter:description',
          content: this.studyArea.metadata.seo_description,
        },
        {
          name: 'twitter:image',
          content: this.studyArea.thumbnail_url
            ? this.studyArea.thumbnail_url
            : imgPlaceholder,
        },
      ],
    };
  },
  computed: {
    studentTestimonial() {
      return this.studyArea.student_testimonial
        ? this.studyArea.student_testimonial
        : null;
    },
    staffTestimonial() {
      return this.studyArea.staff_testimonial
        ? this.studyArea.staff_testimonial
        : null;
    },
    staffProfiles() {
      return this.studyArea.staff_profiles
        ? this.studyArea.staff_profiles
        : null;
    },
    firstMidBannerImg() {
      return this.studyArea.mid_page_banners &&
        this.studyArea.mid_page_banners.banner_1
        ? this.studyArea.mid_page_banners.banner_1.image_url
        : '';
    },
    metaTitle() {
      if (this.studyArea && !this.studyArea.error) {
        if (this.studyArea.metadata.seo_title) {
          return `${this.studyArea.metadata.seo_title}`;
        }
        return `${this.studyArea.title} courses ${this.$t(
          'app.meta.title.suffix',
        )}`;
      }
      return 'Study area';
    },
    studyAreaCategory() {
      return this.studyArea.top_interest
        ? 'Study area'
        : 'Study area subcategory';
    },
    studyArea() {
      return (
        this.$store.getters['study-areas/studyAreaSlug'](
          this.$route.params.id,
        ) || {
          error: true,
          errorMessage: 'Unable to fetch study area data',
        }
      );
    },
    videoDetails() {
      return this.studyArea.video_section;
    },
    videoObject() {
      return {
        url: this.videoDetails['video-api'].video.url,
        youkuUrl: this.videoDetails['video-api'].video.url_youku,
        duration: this.videoDetails['video-api'].video.duration,
        ariaLabel: this.videoDetails['video-api'].label,
      };
    },
    videoPoster() {
      return this.videoDetails['video-api'].poster;
    },
    relatedLinks() {
      const links = get(this.studyArea, ['related_links'], []);
      return links.map((item) => ({
        href: item.link,
        title: item.title,
        category: item.category,
      }));
    },
    relatedComponents() {
      /* eslint-disable camelcase */
      const { related_components } = this.studyArea;

      return this.sortByTitle(related_components).map((component) => {
        const {
          filter_type,
          debug,
          title,
          category,
          offering_type,
          slug,
          offering_level,
          filter_second_level,
        } = component;

        return {
          id: debug.matrix.matrix_id,
          title,
          filterType: filter_type,
          filterSecondLevel: filter_second_level.toLowerCase(),
          offeringLevel: offering_level,
          category: category || 'Major',
          type: category,
          link: `${this.basePath}/courses/${offering_type}/${
            slug || sluggify(title)
          }/`,
        };
      });
      /* eslint-enable camelcase */
    },
    relatedCourses() {
      /* eslint-disable camelcase */
      const { related_courses } = this.studyArea;

      return this.sortByTitle(related_courses).map((course) => {
        const {
          title,
          category,
          slug,
          debug,
          filter_type,
          filter_study_mode,
          offering_level,
          filter_second_level,
        } = course;

        return {
          id: debug.matrix.matrix_id,
          title,
          offeringLevel: offering_level,
          filterDeliveryMode: filter_study_mode,
          filterType: filter_type,
          filterSecondLevel: filter_second_level.toLowerCase(),
          category,
          type: category,
          link: `${this.basePath}/courses/${offering_level}/${
            slug || sluggify(title)
          }/`,
        };
      });
      /* eslint-enable camelcase */
    },
    relatedShortCourses() {
      /* eslint-disable camelcase */
      const related_short_courses = get(
        this.studyArea,
        ['related_short_courses'],
        [],
      );
      return related_short_courses.map((course) => {
        const {
          title,
          slug,
          category,
          offering_type,
          filter_commencement,
          filter_study_mode,
          filter_time,
        } = course;

        return {
          filterCommencement: filter_commencement,
          filterDeliveryMode: filter_study_mode,
          filterTime: filter_time,
          filterCategory: true,
          title,
          type: offering_type,
          category,
          link: `${this.basePath}/short-courses/${slug}`,
        };
      });
      /* eslint-enable camelcase */
    },
    relatedMicrocredentials() {
      /* eslint-disable camelcase */
      const related_micro_certificates = get(
        this.studyArea,
        ['related_micro_certificates'],
        [],
      );
      return related_micro_certificates.map((course) => {
        const {
          title,
          category,
          offering_type,
          slug,
          filter_commencement,
          filter_study_mode,
          filter_time,
        } = course;

        return {
          filterCommencement: filter_commencement,
          filterDeliveryMode: filter_study_mode,
          filterTime: filter_time,
          filterCategory: true,
          title,
          type: offering_type,
          category,
          link: `${this.basePath}/microcredentials/${slug}`,
        };
      });
      /* eslint-enable camelcase */
    },
    combinedShortCoursesAndMicrocredentials() {
      return this.sortByTitle([
        ...this.relatedShortCourses,
        ...this.relatedMicrocredentials,
      ]);
    },
    relatedPathways() {
      const relatedPathways = this.studyArea.related_pathways;

      return relatedPathways.map((item) => ({
        title: item.short_title,
        type: 'pathway',
        link: `${this.basePath}/pathways/${item.slug}/`,
      }));
    },
    relatedStudyAreas() {
      const relatedStudyAreas = this.studyArea.sub_interests;
      return relatedStudyAreas.map((item) => ({
        title: item.title,
        type: 'interest',
        link: `${this.basePath}/study-areas/${item.slug}/`,
      }));
    },
    topInterestsMeta() {
      if (!this.studyArea.top_interests) {
        return '';
      }
      return this.studyArea.top_interests
        ?.map((interest) => interest.title)
        .join(';');
    },
    topInterestsDisplayMeta() {
      if (!this.studyArea.top_interests) {
        return '';
      }
      const rootUrl = process.env.ROOT_URL;
      return this.studyArea.top_interests
        ?.map(
          (interest) =>
            `${interest.title}[${rootUrl}${this.basePath}/study-areas/${interest.slug}/]`,
        )
        .join('|');
    },
  },
  methods: {
    testimonialHasContent(testimonial) {
      return (
        testimonial &&
        (testimonial.testimonials_text ||
          testimonial.testimonials_long ||
          testimonial.testimonials_video)
      );
    },
    sortByTitle(data) {
      return [...data].sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        // eslint-disable-next-line no-magic-numbers,no-nested-ternary
        return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
      });
    },
    setTabActive(e) {
      trackAnalytics('In-page Tab', 'Click', [e]);
    },
    sluggify(text) {
      return sluggify(text);
    },
  },
  meta: {
    pagetype: 'study area',
  },
};
</script>

<style lang="postcss">
.interest {
  padding-bottom: 0;

  .related-courses__heading {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    font-weight: var(--fw-bold);

    @media (--bp-wide) {
      padding-top: 5rem;
      padding-left: 0;
      padding-right: 0;
    }

    .heading-md {
      margin-bottom: 0;
    }
  }

  .app-tab {
    padding-top: 2.5rem;
  }
  .card__thumb {
    margin-top: 1.5rem;
  }

  .card--bdr,
  .card--bdr:hover {
    border-bottom: 0;
  }
}
</style>
