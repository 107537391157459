<template>
  <div
    ref="courselist"
    class="course__filter-list"
    data-test="course-list"
    aria-label="course list"
    tabindex="-1"
  >
    <FilterWidget
      v-if="hasFilterType || hasDeliveryMode || hasFilterCategory"
      :key="componentKey"
      v-slot="{ item }"
      :filter-config="filterConfig"
      :filter-predicate="filterPredicate"
      :view-more="5"
    >
      <CardCourse
        :title="item.title"
        :category="item.category"
        :type="item.type"
        :link="item.link"
      />
    </FilterWidget>
    <CardCourseList v-else :items="coursesData" />
  </div>
</template>

<script>
import {
  CardCourse,
  CardCourseList,
  FilterWidget,
} from '@unimelb/pattern-lib-vue';

export default {
  components: {
    CardCourse,
    CardCourseList,
    FilterWidget,
  },
  props: {
    coursesData: {
      type: Array,
      default: () => [{}],
    },
  },
  data() {
    return {
      userSelected: '',
      isDefaultFilterApplied: true,
      selectedFilterTypeLabel: [],
      selectedDeliveryModeLabel: [],
      resultsFilteredByOfferingLevels: [],
      resultsFilteredByDeliveryModes: [],
      resultsFilteredByCategories: [],
      componentKey: 0,
    };
  },
  computed: {
    filterConfig() {
      const filterConfig = [];
      const courseTypes = {
        name: 'courseTypes',
        filterBy: 'Level',
        options: this.hasSecondLevel
          ? this.offeringLevelsOptions(this.coursesData)
          : this.filterOptions(this.coursesData, 'filterType'),
        placeholderLabel: {
          plural: 'options',
          singular: 'option',
        },
      };
      const deliveryModes = {
        name: 'deliveryModes',
        filterBy: 'Study Mode',
        options: this.filterOptions(this.coursesData, 'filterDeliveryMode'),
        placeholderLabel: {
          plural: 'study modes',
          singular: 'study modes',
        },
      };
      const categories = {
        name: 'categories',
        filterBy: 'Category',
        options: this.filterOptions(this.coursesData, 'category'),
        placeholderLabel: {
          plural: 'categories',
          singular: 'category',
        },
      };
      if (this.hasFilterType) {
        filterConfig.push(courseTypes);
      }
      if (this.hasDeliveryMode) {
        filterConfig.push(deliveryModes);
      }
      if (this.hasFilterCategory) {
        filterConfig.push(categories);
      }

      return filterConfig;
    },
    hasDeliveryMode() {
      const deliveryMode = this.coursesData.find(
        (course) => course.filterDeliveryMode,
      );
      return !!deliveryMode;
    },
    hasFilterType() {
      const filterType = this.coursesData.find((course) => course.filterType);
      return !!filterType;
    },
    hasFilterCategory() {
      const filterCategory = this.coursesData.find(
        (course) => course.filterCategory,
      );
      return !!filterCategory;
    },
    hasSecondLevel() {
      const filterSecondLevel = this.coursesData.find(
        (course) => course.filterSecondLevel,
      );
      return !!filterSecondLevel;
    },
    userProfile() {
      return this.$store.state.profile;
    },
    isMethodDefault() {
      return this.userProfile.method === 'default';
    },
  },
  watch: {
    selectedFilterTypeLabel: {
      handler() {
        if (this.hasSecondLevel && !this.isMethodDefault) {
          this.isDefaultFilterApplied =
            this.isSelectedFilterEqualSegmentationQualification(
              this.selectedFilterTypeLabel,
              this.mapUserProfile(this.userProfile.qualification),
            );
        }
      },
      deep: true,
    },
  },
  methods: {
    filterPredicate({ courseTypes, deliveryModes, categories }) {
      const { coursesData } = this;

      if (this.hasFilterType) {
        if (this.hasDeliveryMode) {
          this.resultsFilteredByOfferingLevels = this.filterByOfferingLevels(
            coursesData,
            courseTypes,
          );
          this.resultsFilteredByDeliveryModes = this.filterByDeliveryMode(
            this.resultsFilteredByOfferingLevels,
            deliveryModes,
          );
          return this.resultsFilteredByDeliveryModes;
        }

        this.resultsFilteredByOfferingLevels = this.filterByOfferingLevels(
          coursesData,
          courseTypes,
        );
        return this.resultsFilteredByOfferingLevels;
      }

      if (this.hasFilterCategory) {
        if (this.hasFilterCategory) {
          this.resultsFilteredByCategories = this.filterByCategory(
            coursesData,
            categories,
          );
          this.resultsFilteredByDeliveryModes = this.filterByDeliveryMode(
            this.resultsFilteredByCategories,
            deliveryModes,
          );
          return this.resultsFilteredByDeliveryModes;
        }

        this.resultsFilteredByCategories = this.filterByCategory(
          coursesData,
          categories,
        );
        return this.resultsFilteredByCategories;
      }

      if (this.hasDeliveryMode) {
        this.resultsFilteredByDeliveryModes = this.filterByDeliveryMode(
          coursesData,
          deliveryModes,
        );
        return this.resultsFilteredByDeliveryModes;
      }

      return coursesData;
    },
    filterByOfferingLevels(allResults, courseTypes) {
      let results = allResults;

      if (this.hasSecondLevel) {
        const selectedFilterTypeLabelFirstLevel = courseTypes.map(
          ([, filterSecondLevel]) => filterSecondLevel,
        );
        results = results.filter((result) =>
          selectedFilterTypeLabelFirstLevel.includes(result.filterSecondLevel),
        );
        this.selectedFilterTypeLabel = courseTypes.map(
          ([, , filterType]) => filterType,
        );
      } else {
        this.selectedFilterTypeLabel = courseTypes.map(
          ([, filterType]) => filterType,
        );
      }

      return results.filter((result) =>
        this.selectedFilterTypeLabel.includes(result.filterType),
      );
    },
    filterByDeliveryMode(allResults, deliveryModes) {
      this.selectedDeliveryModeLabel = deliveryModes.map(
        ([, filterDeliveryMode]) => filterDeliveryMode,
      );
      return allResults.filter((result) =>
        result.filterDeliveryMode.find((item) =>
          this.selectedDeliveryModeLabel.includes(item),
        ),
      );
    },
    filterByCategory(allResults, categories) {
      this.selectedCategories = categories.map(([, category]) => category);
      return allResults.filter((result) =>
        this.selectedCategories.includes(result.category),
      );
    },
    offeringLevelsOptions(data) {
      const optionsOfSecondLevel = [];
      let optionsOfFilterType = [];
      const uniqueSecondLevel = [
        ...new Set(data.map((course) => course.filterSecondLevel)),
      ];
      const profileQualification = this.userProfile.qualification;
      const isDefaultChecked = this.segmentationContainFilteredResults(
        uniqueSecondLevel,
        profileQualification,
      );

      uniqueSecondLevel.sort().forEach((filterSecondLevel) => {
        let filteredOptions = data.filter(
          (course) => course.filterSecondLevel === filterSecondLevel,
        );
        const uniqueFilterTypesOptions = [
          ...new Set(filteredOptions.map((course) => course.filterType)),
        ];
        filteredOptions = [];

        uniqueFilterTypesOptions.sort().forEach((filterType) => {
          optionsOfFilterType.push({
            name: filterType,
            label: filterType,
            isChecked:
              !isDefaultChecked && !this.isMethodDefault
                ? this.mapUserProfile(profileQualification).includes(
                    filterSecondLevel,
                  )
                : true,
          });
        });

        optionsOfSecondLevel.push({
          name: filterSecondLevel,
          label: filterSecondLevel,
          options: optionsOfFilterType,
        });

        optionsOfFilterType = [];
      });

      const courseTypes = [
        {
          label: 'All',
          name: 'all',
          options: optionsOfSecondLevel,
        },
      ];

      return courseTypes;
    },
    segmentationContainFilteredResults(offeringLevels, profileQualification) {
      const checker = offeringLevels.every(
        (offeringLevel) =>
          this.mapUserProfile(profileQualification).includes(offeringLevel) ===
          false,
      );
      return checker;
    },
    filterOptions(data, key) {
      const options = [];
      const elements = [];
      let uniqueElements = [];
      const uniqueKeyValue = [...new Set(data.map((item) => item[key]))];

      uniqueKeyValue.sort().forEach((element) => {
        if (Array.isArray(element)) {
          element.forEach((el) => {
            elements.push(el);
          });

          uniqueElements = [...new Set(elements.map((item) => item))];
        } else {
          options.push({
            name: element,
            label: element,
            isChecked: true,
          });
        }
      });

      if (uniqueElements) {
        uniqueElements.sort().forEach((uniqueElement) => {
          options.push({
            name: uniqueElement,
            label: uniqueElement,
            isChecked: true,
          });
        });
      }

      const resultOptions = [
        {
          label: 'All',
          name: 'all',
          options,
        },
      ];

      return resultOptions;
    },
    calculateResultsQuantity(data, key, value) {
      const countedResults = data.filter((item) => item[key] === value);
      return countedResults.length;
    },
    mapUserProfile(profileQualification) {
      if (profileQualification === 'undergrad') {
        return ['undergraduate'];
      }
      if (profileQualification === 'postgrad') {
        return ['graduate'];
      }
      if (profileQualification === 'research') {
        return ['research'];
      }
      return '';
    },
    isSelectedFilterEqualSegmentationQualification(
      selectedFilter,
      profileQualification,
    ) {
      if (selectedFilter.length) {
        const selectedQualification = this.coursesData.filter((course) =>
          selectedFilter.includes(course.filterType),
        );
        return !selectedQualification.some(
          (course) => !profileQualification.includes(course.filterSecondLevel),
        );
      }
      return false;
    },
  },
};
</script>

<style lang="postcss">
/* Overrides */

.course {
  &__filter-list {
    .section-alt {
      padding-top: 1.875rem;

      &__inner {
        padding: 0;
      }
    }
  }
}
</style>
